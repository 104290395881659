import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { MDBDataTableV5 } from 'mdbreact';
import TablePagination from "../pagination/TablePagination";
import { CSVLink } from "react-csv";
import activity from "../activity/Activity";
import ReactTable from 'react-data-table-component';
import { data } from "jquery";

const Campaign = ({ user }) => {
    const [loader, setLoader] = useState(true);
    const currentApp = useSelector(state => state.app.currentApp);
    const [totalRows, setTotalRows] = useState({
        campLength: 0,
        socialLength: 0,
        teamLength: 0,
        clientLength: 0,
        loginLength: 0,
        activityLength: 0,
        ipLength: 0
    });
    const [perPage, setPerPage] = useState(10);

    const [campaigns, setCampaigns] = useState({});
    const [columns, setColumns] = useState([]);

    /** Download CSV */
    const [colHeader, setColHeader] = useState([]);

    const [slides, setSlides] = useState({
        columns: [],   
        rows: []
    });


    const [campTable, setCampTable] = useState({
        columns: [],
        rows: []
    });

    const [columnsSocial, setColumnsSocial] = useState({
        columns: [],
        rows: []
    });

    const [columnsTeam, setColumnsTeam] = useState({
        columns: [],
        rows: []
    });

    const [columnsClient, setColumnsClient] = useState({
        columns: [],
        rows: []
    });

    const [columnsLogin, setColumnsLogin] = useState({
        columns: [],
        rows: []
    });

    const [columnsActivity, setColumnsActivity] = useState({
        columns: [],
        rows: []
    });

    const [columnsIpLogs, setColumnsIpLogs] = useState({
        columns: [],
        rows: []
    });



    const fetchCampaigns = (pageNo, limit, type) => {
        axios({
            method: 'POST',
            url: currentApp.endpoints.fetch_campaign,
            data: { secret_key: currentApp.secret_key, user_id: user?.user_id ? user?.user_id : user?.id, pageno: pageNo, limit: limit },
        }).then((res) => {
            setLoader(false);
            // setCampaigns(res.data);
            if (res.data.status === true) {

                setTotalRows({
                    ...totalRows,
                    campLength: res.data.data.campaignsLength,
                    socialLength: res.data.data.socialLength,
                    teamLength: res.data.data.teamLength,
                    clientLength: res.data.data.clientLength,
                    activityLength: res.data.data.activityLength,
                    loginLength: res.data.data.loginLength,
                    ipLength: res.data.data.ipLength

                });

                // setColHeader(res.data.data.activity_logs);
                if ((res.data.data.campaigns && res.data.data.campaigns.length > 0) && (type === "all" || type === "campaign")) {
                    /** Set Campaign Datatable */

                    setColumns(Object.keys(res.data.data.campaigns[0]));
                    let object = res.data.data.campaigns[0];

                    if (type === "campaign") {
                        return setCampTable({ ...campTable, rows: res.data.data.campaigns });
                    } else {
                        for (const property in object) {
                            let array = campTable.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setCampTable(({ ...campTable, columns: array }));
                        }
                        setCampTable({ ...campTable, rows: res.data.data.campaigns });

                    }
                }

                if ((res.data.data.slides && res.data.data.slides.length > 0) && (type === "all" || type === "slides")) {
                    /** Set Slides Datatable */

                    let object = res.data.data.slides[0];
                    if (type === "slides") {
                        return setSlides({ ...slides, rows: res.data.data.slides });
                    } else {
                        for (const property in object) {
                            let array = slides.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setSlides(({ ...slides, columns: array }));
                        }
                        setSlides({ ...slides, rows: res.data.data.slides });
                    }
                }


                /** Set Social Account Datatable */
                if ((res.data.data.social_account && res.data.data.social_account.length > 0) && (type === "all" || type === "social")) {

                    let objectSocial = res.data.data.social_account[0];

                    if (type === "social") {

                        return setColumnsSocial({ ...columnsSocial, rows: res.data.data.social_account });

                    }
                    else {
                        for (const property in objectSocial) {
                            let array = columnsSocial.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setColumnsSocial(({ ...columnsSocial, columns: array }));

                        }

                        setColumnsSocial({ ...columnsSocial, rows: res.data.data.social_account });
                    }
                }

                /** Set Team Members Datatable */
                if ((res.data.data.teams && res.data.data.teams.length > 0) && (type === "all" || type === "team")) {

                    let objectTeam = res.data.data.teams[0];

                    if (type === "team") {
                        return setColumnsTeam({ ...columnsTeam, rows: res.data.data.teams });
                    }

                    else {
                        for (const property in objectTeam) {
                            let array = columnsTeam.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setColumnsTeam(({ ...columnsTeam, columns: array }));
                        }

                        setColumnsTeam({ ...columnsTeam, rows: res.data.data.teams });
                    }
                }

                /** Set Client Datatables */
                if ((res.data.data.clients && res.data.data.clients.length > 0) && (type === "all" || type === "client")) {

                    let objectTeam = res.data.data.clients[0];

                    if (type === "client") {
                        return setColumnsClient({ ...columnsClient, rows: res.data.data.clients });
                    }
                    else {
                        for (const property in objectTeam) {
                            let array = columnsClient.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setColumnsClient(({ ...columnsClient, columns: array }));
                        }
                        setColumnsClient({ ...columnsClient, rows: res.data.data.clients });
                    }
                }

                /** Set Login Logs Datatables */
                if ((res.data.data.login_logs && res.data.data.login_logs.length > 0) && (type === "all" || type === "login")) {

                    let object = res.data.data.login_logs[0];

                    if (type === "login") {
                        return setColumnsLogin({ ...columnsLogin, rows: res.data.data.login_logs })
                    }
                    else {
                        for (const property in object) {
                            let array = columnsLogin.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setColumnsLogin(({ ...columnsLogin, columns: array }));
                        }

                        setColumnsLogin({ ...columnsLogin, rows: res.data.data.login_logs })
                    }
                }

                /** set Activity Logs Datatables */

                if ((res.data.data.activity_logs && res.data.data.activity_logs.length > 0) && (type === "all" || type === "activity")) {
                    setColHeader(res.data.data.activity_logs);

                    let object = res.data.data.activity_logs[0];

                    if (type === "activity") {
                        return setColumnsActivity({ ...columnsActivity, rows: res.data.data.activity_logs })
                    }
                    else {
                        for (const property in object) {
                            let array = columnsActivity.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setColumnsActivity(({ ...columnsActivity, columns: array }));
                        }

                        setColumnsActivity({ ...columnsActivity, rows: res.data.data.activity_logs })
                    }
                }

                /** set ip_logs Datatables */

                if ((res.data.data.ip_logs && res.data.data.ip_logs.length > 0) && (type === "all" || type === "ip")) {
                    // setColHeader(res.data.data.ip_logs);

                    let object = res.data.data.ip_logs[0];

                    if (type === "ip") {
                        return setColumnsIpLogs({ ...columnsIpLogs, rows: res.data.data.ip_logs })
                    }
                    else {
                        for (const property in object) {
                            let array = columnsIpLogs.columns;

                            let file =
                            {
                                label: property.replace('_', ' ').toUpperCase(),
                                field: property
                            };
                            array.push(file);
                            setColumnsIpLogs(({ ...columnsIpLogs, columns: array }));
                        }
                        setColumnsIpLogs({ ...columnsIpLogs, rows: res.data.data.ip_logs })
                    }
                }


            }

        }).catch(error => {
            console.log(error, "error")
        })
    };



    //console.log(totalRows);
    // console.log("campTable", campTable, "\ncolumnsSocial", columnsSocial, "\ncolumnsTeam", columnsTeam, "\ncolumnsClient", columnsClient, "\ncolumnsLogin", columnsLogin, "\ncolumnsActivity", columnsActivity, "\ncolumnsIpLogs", columnsIpLogs);
    // console.log(campTable);
    //make default Pagination

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchCampaigns(page, newPerPage, "campaign")
        setPerPage(newPerPage);
    };

    const handlePageChange = page => {
        fetchCampaigns(page, perPage, "campaign");
    };

    const handleSocialPerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchCampaigns(page, newPerPage, "social")
        setPerPage(newPerPage);
    };

    const handleSocialPageChange = page => {
        fetchCampaigns(page, perPage, "social");
    };

    const handleTeamPerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchCampaigns(page, newPerPage, "team")
        setPerPage(newPerPage);
    };

    const handleTeamPageChange = page => {
        fetchCampaigns(page, perPage, "team");
    };

    const handleClientPerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchCampaigns(page, newPerPage, "client")
        setPerPage(newPerPage);
    };

    const handleClientPageChange = page => {
        fetchCampaigns(page, perPage, "client");
    };

    const handleLoginPerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchCampaigns(page, newPerPage, "login")
        setPerPage(newPerPage);
    };

    const handleLoginPageChange = page => {
        fetchCampaigns(page, perPage, "login");
    };

    const handleIpPerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchCampaigns(page, newPerPage, "ip")
        setPerPage(newPerPage);
    };

    const handleIpPageChange = page => {
        fetchCampaigns(page, perPage, "ip");
    };

    const handleActivityPerRowsChange = async (newPerPage, page) => {
        setLoader({ isLoading: true });
        fetchCampaigns(page, newPerPage, "activity")
        setPerPage(newPerPage);
    };

    const handleActivityPageChange = page => {
        fetchCampaigns(page, perPage, "activity");
    };


    useEffect(() => {
        fetchCampaigns(1, perPage, "all");
    }, []);

    // make table from react table dynamically

    const dynamicTableColumn = (tableData) => {
        const column = []
        // make table column 
        if (tableData.columns) {
            tableData.columns.forEach((ele, index) => {
                let colData = {
                    name: ele.label,
                    selector: ele.field,
                    wrap: true,
                    maxWidth: "100%"
                }
                column.push(colData);
            })
        }
        return column
    }

    const dynamicTableRow = (tableData) => {
        const row = []
        // make table row
        //console.log("All Data",row);
        if (tableData.rows) {
            let data = {}
            tableData.rows.forEach((ele, index) => {
                let entries = Object.entries(ele);
                entries.forEach((element) => {

                    data = {
                        ...data,
                        [element[0]]: ReactHtmlParser(element[1])
                    }
                });
                row.push(data)
            })
        }

        let data = [];
        // for (let obj of row) {
        //     if (obj.system[0].props.children.length > 1) {
        //         console.log("OLD",obj.system[0].props.children[1].props.children);
        //         let updated_Str = obj.system[0].props.children[0].replaceAll("\\n","\n");
        //         obj.system[0].props.children[0]=updated_Str
        //         let updated_Str2 = obj.system[0].props.children[1].props.children[0].replaceAll("\\n", "\n");
        //         obj.system[0].props.children[1].props.children[0] = updated_Str2;
        //         console.log("NEW",obj.system[0].props.children[1].props.children);
        //     }

        //     data.push(obj)
        // }
        return row
    }
    return (

        <div className="row">
            <div className="col-12">

                {/* Display Campaign Data */}
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4>Campaign Data</h4>
                    </div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(campTable)}
                                            data={dynamicTableRow(campTable)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={campTable.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.campLength || 0}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>

                {/* Display Slides Data */}
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4>Slides Data</h4>
                    </div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(slides)}
                                            data={dynamicTableRow(slides)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={slides.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.campLength || 0}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>

                {/* Display Social Account */}
                <div className="card">
                    <div className="card-header"><h4>Social Account</h4></div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(columnsSocial)}
                                            data={dynamicTableRow(columnsSocial)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={columnsSocial.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.socialLength || 0}
                                            onChangeRowsPerPage={handleSocialPerRowsChange}
                                            onChangePage={handleSocialPageChange}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>

                {/* Display Team Members */}

                <div className="card">
                    <div className="card-header"><h4>Team Member Account</h4></div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(columnsTeam)}
                                            data={dynamicTableRow(columnsTeam)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={columnsTeam.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.teamLength || 0}
                                            onChangeRowsPerPage={handleTeamPerRowsChange}
                                            onChangePage={handleTeamPageChange}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>

                {/* Display  Clients Account */}
                <div className="card">
                    <div className="card-header"><h4>Client Account</h4></div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(columnsClient)}
                                            data={dynamicTableRow(columnsClient)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={columnsClient.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.clientLength || 0}
                                            onChangeRowsPerPage={handleClientPerRowsChange}
                                            onChangePage={handleClientPageChange}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>

                {/* Display Login Logs */}
                <div className="card">
                    <div className="card-header"><h4>Login Logs</h4></div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(columnsLogin)}
                                            data={dynamicTableRow(columnsLogin)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={columnsLogin.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.loginLength || 0}
                                            onChangeRowsPerPage={handleLoginPerRowsChange}
                                            onChangePage={handleLoginPageChange}
                                        />

                                    </div>
                                </>
                        }
                    </div>
                </div>


                {/* Display Activity Logs */}
                <div className="card">
                    <div className="card-header d-flex justify-content-between"><h4>Activity Logs</h4><CSVLink data={colHeader}>Download CSV</CSVLink></div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(columnsActivity)}
                                            data={dynamicTableRow(columnsActivity)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={columnsActivity.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.activityLength || 0}
                                            onChangeRowsPerPage={handleActivityPerRowsChange}
                                            onChangePage={handleActivityPageChange}
                                        />

                                    </div>
                                </>
                        }
                    </div>
                </div>


                {/* Display ip_logs */}
                <div className="card">
                    <div className="card-header d-flex justify-content-between"><h4>IP Logs</h4>
                    </div>
                    <div className="card-body">
                        {
                            loader ?
                                <Skeleton height={35} count={10} />
                                :
                                <>
                                    <div className="table-overflow">
                                        < ReactTable
                                            columns={dynamicTableColumn(columnsIpLogs)}
                                            data={dynamicTableRow(columnsIpLogs)}
                                            selectableRows
                                            pagination
                                            paginationServer
                                            progressPending={columnsIpLogs.rows !== 0 ? false : true}
                                            responsive={true}
                                            highlightOnHover={true}
                                            paginationTotalRows={totalRows.ipLength || 0}
                                            onChangeRowsPerPage={handleIpPerRowsChange}
                                            onChangePage={handleIpPageChange}
                                        />

                                    </div>
                                </>
                        }
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Campaign;